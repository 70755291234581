.App {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: auto;
}

.site__title
{
  font-family: 'Rock Salt', cursive;
  font-size: 2rem;
    text-align: center;
    width: 90%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.NavBar
{
  max-width: 80%;
  display: flex;
  text-align: center;
  margin: auto;
}

.NavBar__item
{
  font-family: 'Rock Salt', cursive;
  font-size: 1rem;
  padding: 20px;
}

.Landing__title
{
  text-align: center;
}

.Landing__text
{
  font-size: 24px;
}

.Card__text
{
  padding-left: 5%;
  padding-right: 5%;
}

.Landing__card
{
  color: white;
  max-width: 80%;
  border: solid #14005b 2px;
  /* background-color: rgb(21, 89, 21); */
  background: linear-gradient(rgb(162, 150, 18),rgb(213, 213, 42));
  box-shadow: 5px 10px 18px black;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 1px;
  padding-left: 8px;
}

.intro
{
  font-size: 20px;
  margin: auto;
  max-width: 80%;
}
.Resume
{
  max-width: 96%;
  margin: auto;
  width: auto;
}

.Resume__select_and_label 
{
  width: 80%;
  margin: auto;
  padding: 10px;
  align-items: center;
}



.Resume__card
{
  color: white;
  max-width: 90%;
  border: solid #7b190a 2px;
  /* background-color: rgb(174, 0, 0); */
  background: linear-gradient(rgb(174, 0, 0),rgb(238, 41, 41));
  box-shadow: 5px 10px 18px black;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 2em;
  /* padding-top: 1px;
  padding-left: 8px; */
}

.Projects
{
  margin-top: 1em;
  max-width: 96%;
}

.Project__card
{
  color: white;
  max-width: 80%;
  border: solid #32012b 2px;
  /* background-color: rgb(89, 21, 108); */
  background: linear-gradient(rgb(89, 21, 108),rgb(151, 48, 179));
  box-shadow: 5px 10px 18px black;
  margin: auto;
  border-radius: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 2em;
  /* padding-top: 1px;
  padding-left: 8px; */
  max-width: 800px;
  width: 90%;
}

.Project__video
{
  display: block;
  margin: auto;
  padding-top: 1em;
  width: 540px;
  height: 320px;
}

.Project__pic_and_details
{
  display: flex;
  flex-direction: row;
  padding: 3%;
}

.Project__details_column
{
  width: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.Project__image
{
  display: block;
  max-height: 400px;
  height: auto;
  max-width: 100%;
  width: auto;
  padding-top: 2em;
  padding-left: 1em;
}

.Blog__card
{
  color: white;
  max-width: 80%;
  border: solid #0c027b 2px;
  /* background-color: rgb(21, 70, 108); */
  background: linear-gradient(rgb(21, 70, 108),rgb(53, 131, 191));
  box-shadow: 5px 10px 18px black;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 1px;
  padding-left: 8px;
  text-align: center;
}

.About__card
{
  color: white;
  max-width: 80%;
  border: solid #cec819 2px;
  /* background-color: rgb(152, 118, 8); */
  background: linear-gradient(rgb(21, 70, 108),rgb(53, 131, 191));
  box-shadow: 5px 10px 18px black;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 1em;
  padding-left: 8px;
}

.About__title
{
  font-size: 30px;
  margin-left: 6%;
}

.About__subtitle
{
  font-size: 20px;
  margin-left: 10%;
  text-align: left;
}

.About__text-and-pic
{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding: 1em;
}

.About__profile-pic
{
  display: flexbox;
  align-self: top;
  width: auto;
  height: 250px;
  order: 2;
  margin-left: 5%;
  padding-top: 2em;
  border-radius: 10px;
  max-height: 300px;
  max-width: 100%;
  
}

.About__profile-text
{
  flex-direction: column;
}

.About__text
{
  margin-left: 5px;
  margin-right: 5%;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  max-width: 100%;
}

h2
{
  font-family: 'Rock Salt', 'cursive';
  font-size: large;
  font-weight: bold;
}

h3
{
  font-family: 'Raleway', sans-serif;
  font-size: medium;
  font-weight: bold;
}

p
{
  font-family: 'Raleway', sans-serif;
  font-size: medium;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .site__title
{
    font-size: 1rem;
}

.NavBar__item
{
  font-size: 0.7rem;
  padding: 10px;
}

.intro
{
  font-size: 12px;
}

.Landing__title
{
  font-size: 16px;
}

.Landing__text
{
  font-size: 18px;
}

.Resume__dropdown_label
{
  font-size: xx-small;
}

.Resume__dropdown
{
  font-size: xx-small;
}

.Resume__dropdown_option
{
  width: 100px;
}

.Project__card
{
  padding: 1em;
}

.Project__video
{
  width:280px;
  height:160px;
}

.Project__pic_and_details
{
  flex-direction: column;
  padding: 3%;
  width: 100%;
  margin: auto;
  padding: 0;
}

.Project__details_column
{
  order: 2;
  width: 100%;
  margin: auto;
}

.Project__image
{
  order: 1;
  max-height: 200px;
  max-width: 100%;
  margin: auto;
}

.About__title
{
  font-size: 20px;
  margin: auto,
}

.About__subtitle
{
  font-size: 12px;
  text-align: center;
  margin: auto,
}

.About__text-and-pic
{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 1em;
}

.About__profile-pic
{
  order: 1;
  display: flex;
  max-width: 70%;
  width: auto;
  height: auto;
  margin: auto;
  border-radius: 10px;
}

h2
{
  font-size: medium;
  text-align: center;
}

h3
{
  font-size: small;
  text-align: center;
}

}
